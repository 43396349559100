#detailspane>div {
    scrollbar-color:  #D6DCE4 #f5f7f9;
  }
#detailspane>div::-webkit-scrollbar-thumb {
    border: 3px solid #f5f7f9;
  }

#detailspane svg {
    position: absolute; 
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
}

#detailspane {
    display:inline-block;
    width: 90vw;
    max-width: 500px;
    max-height: 800px;
    padding: 50px 20px 30px 40px;
    border-radius: 30px;
    background:#f5f7f9;
    top: 135px;
    position: absolute;
    right: 20px;
    bottom: 30px;
    font-size: 14px;
    text-align:right;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

#detailspane div {
    height: 100%;
    overflow: auto;
    padding-right:10px;
    padding-bottom: 30px;
}

#detailspane .title {
    text-transform: uppercase;
    color:#1620A3;
    font-weight: 900;;
    margin-top: 20px;
    margin-bottom: 5px;
}

#detailspane h2 {
    text-transform: uppercase;
    font-weight: 900;
    color:#1620A3;
    margin: 30px 0 10px;
}

#detailspane p {
    margin: 10px 0;
}