.infobox>div {
    scrollbar-color:  #D6DCE4 #f5f7f9;
  }
.infobox>div::-webkit-scrollbar-thumb {
    border: 3px solid #f5f7f9;
  }

.information {
    background: white;
}

.infobox {
    position:absolute;
    z-index: 2;
    background:#f5f7f9;
    padding: 30px 10px 30px 30px;
    border-radius: 30px;
    left: 50%;
    top: 50%;
    transform:translate(-50%, -50%);
    height: 70vh;
    width: 95%;
    max-width: 800px;
    overflow:auto;
    display: none;
    box-shadow: rgba(5, 5, 0, 0.15) 0px 3px 8px;
}
.infobox>div {
    width: 100%;
    height: 100%;
    padding-right: 20px;
    overflow:auto;
    font-size: 14px;
}

.bg-close {
    transition: 0.5s;
    position: fixed;
    left:0;
    right:0;
    top:0;
    bottom: 0;
    z-index: 1;
    background: rgba(214, 220, 228, 0.2);
    backdrop-filter: blur(5px);
}


.opened {
    display: block;
}

.infobox h2 {
    color: #1620A3;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 900;
}
.infobox h3 {
    color: #1620A3;
    margin-bottom: 14px;
    font-weight: 700;
}

.infobox div p {
    margin-bottom: 14px;
}