.score {
    display: grid;
    gap: 20px;
    grid-template-columns: 130px 50px auto;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 4px;
    margin-top: 10px;
    position: relative;
    padding: 10px;
}

.score span {
    vertical-align: center;
    display:inline-block;
}

.bar {
    height: 20px;
    margin-bottom: 3px;
    width: 30px;
    background-color:#8496B0;
    display:block;
}
.score:nth-child(odd) {background-color: #E0E6EE;}

.score .bar:first-child {
    border-radius: 5px 5px 0 0;
}
.score .bar:last-child {
    border-radius: 0 0 5px 5px;
}

.answer {
    font-weight: 400;
}