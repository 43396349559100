header {
  margin: 50px 30px 0;
  display: flex;
  align-items:baseline;
  justify-content: left;
  gap: 20px;
}

#page_title {
  display: inline-block;
}